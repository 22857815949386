/* Global styles */
body {
    font-family: Arial, sans-serif;
    margin: 10;
    padding: 10;
    background-color: rgb(163, 163, 163);
}

h1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

h2 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 1px;
}
/* Buttons */
button {
    padding: 4px 10px;
    margin: 5px;
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: rgba(168, 137, 168, 0.788); /* Darker shade on hover */
}

/* Filter Button Specific Styling */
.filter-button {
    background-color: black;
    color: white;
}

.filter-button:hover {
    background-color: #5a5a5a; /* Slightly lighter black on hover */
}

.back-button {
    padding: 4px 10px;
    margin: 5px;
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: rgba(168, 137, 168, 0.788); /* Darker shade on hover */
  }

/* adminpage specific styling */

.duration-cell {
    color: black;
  }

/* Forms */
input[type="date"],
input[type="text"],
input[type="number"],
input[type="email"],
textarea {
    width: 100%;
    padding: 5px;
    margin: 1px 0;
    border: 1px solid #acabab;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Adjust the width of the date range selectors */
input[type="date"] {
    width: auto; /* Change this to the desired width */
}

/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #5e5e5e;
    padding: 10px;
    text-align: left;
}

th {
    font-weight: bold;
    color: white;
    background-color: black;
}

tr:nth-child(even) {
    background-color: #777676ab;
}

tr:hover {
    background-color: #f1f1f1;
}

/* Modals */
.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    max-width: 60%;
    width: 300px;
    z-index: 1000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}

.Modal h2 {
    margin-top: 0;
    grid-column: span 2;
}

.Modal input,
.Modal textarea {
    width: calc(100% - 20px);
    margin: 5px 0 10px;
}

.Modal button {
    width: calc(100% - 20px);
    margin-top: 10px;
    grid-column: span 2;
}

/* Overlay */
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Login Page */
.login-container {
    width: 300px;
    margin: 250px auto;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.login-input {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.login-button {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    background-color: #671c99;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #aa4fe7
}

.login-error {
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
}



/* edit button styling */
.edit-button {
    padding: 5px 10px;
    margin: 5px;
    background-color: white;
    color: black;
    border: 1px solid black; /* Combined border properties */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-button:hover {
    background-color: #f2f2f248; 
    border: 1px solid black; /* Ensure the border remains consistent on hover */
}

/* delete button */
.delete-button {
    padding: 5px 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #d32f2f;
}

/* Leaderboard.css */
.leaderboard {
    width: 90%;
    margin: 10px;
    text-align: center;
}

.leaderboard-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.leaderboard-rank img {
    width: 50px;
    height: 50px;
}

.leaderboard-details {
    flex: 1;
    margin-left: 20px;
    text-align: left;
}

.leaderboard-progress {
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 50px;
    margin: 5px 0;
}

.progress-bar {
    height: 100%;
    transition: width 0.3s ease;
}

.red {
    background-color: red;
}

.yellow {
    background-color: yellow;
}

.orange {
    background-color: orange;
}

.green {
    background-color: green;
}

.purple {
    background-color: purple;

}

.leaderboard-amount {
    font-size: 1.1em;
}

/* UserModal.css */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.user-table th,
.user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.user-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table tr:hover {
    background-color: #f1f1f1;
}

/* Fullscreen specific styling */
.fullscreen {
    position: fixed;
    top: 10px;
    left: 30px;
    margin-left: 20px;
    right: 10px;
    bottom: 1px;
    width: 97vw;
    height: 95vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: rgb(163, 163, 163);
    color: rgb(17, 17, 17);
  }
  
  .fullscreen:hover .exit-button {
    display: block;
    background-color: red;
    color: whitesmoke;
  }
  
  .fullscreen .leaderboard {
    width: 90%;
    margin: 0;
  }
  
  .fullscreen .leaderboard-list {
    flex-grow: 1;
    width: 100%;
    max-height: calc(90vh - 80px);
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .fullscreen .leaderboard-item {
    justify-content: space-between;
  }
  
  .fullscreen .leaderboard-details {
    margin-left: 10px;
  }
  
  .leaderboard .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .leaderboard .header h1 {
    margin: 0;
  }
  
  .leaderboard .exit-button {
    display: none;
    padding: 8px 16px;
    background-color: whitesmoke;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .leaderboard .exit-button:hover {
    background-color: red;
    color: whitesmoke;
  }
  
  .leaderboard-item {
    background-color: rgb(163, 163, 163);
  }
  
  .leaderboard-item:hover {
    background-color: #757677;
  }
  
  .page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    /* margin-bottom: 5px; */
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60px; /* Adjust the height as needed */
  }
  
  .logo {
    height: 90px; /* Adjust the height as needed */
    margin-right: 10px; /* Adjust the margin as needed */
    position: absolute;
    left: 20px; /* Adjust the left position as needed */
  }
  
  h1 {
    margin: 0 auto;
    font-family: 'Times New Roman', Times, serif; /* Center the text horizontally */
  }
  
  /* Fullscreen header height adjustment */
  .fullscreen .header {
    height: 60px;
  }
  
  .progress-bar {
    height: 80px; /* Increase the height of the progress bar */
    transition: width 1s ease;
    position: relative;
  }
  
  .car-image {
    position: absolute;
    left: calc(100% - 1px); /* Position the left edge of the car image at the rightmost edge of the progress bar */
    top: 5%;
    transform: translateY(-5%);
    height: 50px; /* Adjust the height of the car image */
  }
  
  .fullscreen .progress-bar {
    height: 100%;
    transition: width 1s ease;
    position: relative;
  }
  
  .fullscreen .table-container {
    flex-grow: 1;
    width: 100%;
    max-height: calc(90vh - 20px);
    overflow-y: auto;
    border-collapse: collapse;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .fullscreen table {
    width: 100%;
    height: 80vh;
  }
  
  .fullscreen th,
  .fullscreen td {
    padding: 12px;
    text-align: center;
  }
  
  .fullscreen th {
    background-color: #331e36;
    color: whitesmoke;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 60px;
  }
  
  .fullscreen tr {
    height: 70px;
  }
  
  /* .fullscreen th:nth-last-child(1),
  .fullscreen td:nth-last-child(1) {
    display: none; */
  /* } */
  
  .fullscreen td:nth-child(1) {
    background-color: #3d2640;
  }
  
  .fullscreen td:nth-child(2) {
    background-color: #472e4a;
  }
  
  .fullscreen td:nth-child(3) {
    background-color: #523653;
  }
  
  .fullscreen td:nth-child(4) {
    background-color: #5c3e5d;
  }
  
  .fullscreen td:nth-child(5) {
    background-color: #664767;
  }
  
  .fullscreen td:nth-child(6) {
    background-color: #715071;
  }
  
  .fullscreen td:nth-child(7) {
    background-color: #7b597b;
  }
  
  .fullscreen td:nth-child(8) {
    background-color: #856285;
  }
  
  .fullscreen td:nth-child(9) {
    background-color: #906b8f;
  }
  
  .fullscreen td:nth-child(10) {
    background-color: #9a7499;
  }
  
  .fullscreen td:nth-child(11) {
    background-color: #a47da3;
  }
  
  .fullscreen td:nth-child(12) {
    background-color: #ae86ad;
  }
  
  .fullscreen td:nth-child(13) {
    background-color: #b890b7;
  }
  
  .fullscreen td:nth-child(14) {
    background-color: #c299c1;
  }
  
  .fullscreen td:nth-child(15) {
    background-color: #cba2cb;
  }
  
  .fullscreen td:nth-child(16) {
    background-color: #d5abd5;
  }
  
  .fullscreen td:nth-child(17) {
    background-color: #dfb4df;
  }
  
  .fullscreen tbody tr {
    color: whitesmoke;
  }
  
  .fullscreen tbody tr:nth-child(odd) {
    background-color: #442b48;
  }
  
  .fullscreen tbody tr:hover {
    background-color: lightgrey;
    color: black;
  }
  
  .fullscreen .delete-button {
    display: none;
  }
  
  .fullscreen .back-button,
  .fullscreen .exit-button {
    display: none;
  }
  
  .leaderboard-name {
    width: 150px; /* Adjust based on your layout */
    margin-right: 10px; /* Space between name and progress bar */
    text-align: right; /* Align text to the right */
}

.finish-line {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: red;
}

.progress-bar-container {
    position: relative;
    flex-grow: 1; /* Make it take the remaining space */
    height: 50px; /* Adjust as needed */
}

.goal-display {
    text-align: center;
    /* margin-bottom: 5px; */
    height: 30px; /* Space between goal and leaderboard */
}

.fullscreen-container {
    display: flex;
}

/* //////////////////////////////////////////////test/////// */

.leaderboard-details {
    flex-grow: 1;
    margin-left: 20px;
    text-align: left;
}

.leaderboard-name {
    width: 100%; /* Adjust the width to take the full space */
    text-align: left; /* Align the agent name to the left */
    font-weight: bold; /* Make the agent name bold */
    margin-bottom: 5px; /* Add margin at the bottom of the agent name */
}

.leaderboard-progress {
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 40px;
    margin: 5px 0;
    position: relative; /* Set position to relative */
}

.progress-bar-container {
    position: relative;
    height: 100%;
}

.progress-bar {
    height: 100%;
    transition: width 0.3s ease;
    position: absolute; /* Set position to absolute */
    left: 0;
    top: 0;
    bottom: 0;
}

.car-image {
    position: absolute;
    left: calc(100% - 3px);
    top: 50%; /* Center the car image vertically */
    transform: translateY(-50%);
    height: 40px;
}

.goal-line {
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: black;
    z-index: 1; /* Adjusted z-index to ensure it's above the progress bar */
}

.progress-bar-container {
    position: relative;
    flex-grow: 1; /* Make it take the remaining space */
    height: 50px; /* Adjust as needed */
}

.goal-display {
    text-align: center;
    /* margin-bottom: 10px;  */
}

.fullscreen-container {
    display: flex;
}

.leaderboard-names-column {
    flex: 0 0 150px; /* Adjust width as needed */
    margin-right: 10px; /* Space between names and progress bars */
    display: flex;
    flex-direction: column;
}

.leaderboard-items {
    flex-grow: 1;
    margin-top: 0px; /* Allow the items to take the remaining space */
}

/* //////////////////////////////////////////////test/////// */
.leaderboard {
    width: 95%;
    min-height: fit-content;
    margin: 10px auto;
    text-align: center;
    /* overflow-y: auto; Make the leaderboard scrollable */
    max-height: calc(95vh); /* Adjust the maximum height based on your layout */
}

.leaderboard-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: 100%; /* Ensure each leaderboard item takes the full width */
}

.leaderboard-details {
    flex-grow: 1;
    margin-left: 20px;
    text-align: left;
}

.leaderboard-name {
    width: 100%; /* Adjust the width to take the full space */
    text-align: left; /* Align the agent name to the left */
    font-weight: bold; /* Make the agent name bold */
    margin-bottom: 5px; /* Add margin at the bottom of the agent name */
}

.leaderboard-progress {
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 50px;
    margin: 5px 0;
    position: relative; /* Set position to relative */
}

.progress-bar-container {
    position: relative;
    height: 100%;
}

.progress-bar {
    height: 100%;
    transition: width 0.3s ease;
    position: absolute; /* Set position to absolute */
    left: 0;
    top: 0;
    bottom: 0;
}

.car-image {
    position: absolute;
    left: calc(100% - 4px);
    top: 50%; /* Center the car image vertically */
    transform: translateY(-50%);
    height: 50px;
}

.goal-line,
.finish-line {
    position: absolute;
    width: 2px;
    background-color: black;
}

.finish-line {
    left: 100%;
}

.leaderboard-progress {
    position: relative; /* Set position to relative */
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 50px;
    margin: 5px 0;
}

.progress-bar-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.goal-line {
    position: absolute;
    right: 0;
    top: 0;
    width: 8px; /* Adjust the width if needed */
    /* background-image: url('images/finishLine.png'); Path to your checkered pattern image */
    background-size: 100% 100%; /* Ensure the pattern covers the entire element */
    z-index: 13; /* Ensure it's above the progress bar */
}

.goal-input {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .goal-input label {
    font-size: 14px;
    margin-right: 10px;
  }
  
  .goal-input input {
    width: 80px; /* Adjust the width as needed */
    padding: 5px;
    font-size: 14px;
  }

/* //////////////// Agent page additional//////////// */

.agent-report-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 60px;
  }

  .agent-page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  /* //////////////////enrollments//////////////////// */
/* General table styling */
th.cancellationAmount {
    background-color: red;
    color: black;
}

td.cancellationAmount {
    background-color: #ffcccc; /* Lighter red */
    color: black;
}

th.cancellationCount {
    background-color: orange;
    color: black;
}

td.cancellationCount {
    background-color: #ffe5cc; /* Lighter orange */
    color: black;
}

th.agent {
    background-color: aqua;
    color: black;
}

td.agent {
    background-color: #e0ffff; /* Lighter aqua */
    color: black;
}

th.dayCount {
    background-color: green;
    color: black;
}

td.dayCount {
    background-color: #ccffcc; /* Lighter green */
    color: black;
}

th.dayAmount {
    background-color: black;
    color: white;
}

td.dayAmount {
    background-color: #2c2c2c; /* Lighter black (gray) */
    color: white;
}

th.pendingCount {
    background-color: #006aff;
    color: black;
}

td.pendingCount {
    background-color: #88b6f6; 
    color: black;
}

th.pendingAmount {
    background-color: black;
    color: white;
}

td.pendingAmount {
    background-color: #2c2c2c; /* Lighter black (gray) */
    color: white;
}

th.weekCount {
    background-color: purple;
    color: black;
}

td.weekCount {
    background-color: rgb(211, 183, 211); 
    color: black;
}

th.weekAmount {
    background-color: black;
    color: white;
}

td.weekAmount {
    background-color: #2c2c2c; /* Lighter black (gray) */
    color: white;
}

th.monthAmount {
    background-color: rgb(180, 235, 53);
    color: black;
}

td.monthAmount {
    background-color: rgb(171, 226, 180);
    color: black;
}

th.quarterAmount {
    background-color: black;
    color: white;
}

td.quarterAmount {
    background-color: #2c2c2c; /* Lighter black (gray) */
    color: white;
}

.actions {
    background-color: black;
    color: white;
}

/* Fullscreen specific styling */
.fullscreen th.cancellationAmount {
    background-color: red;
    color: black;
}


.fullscreen td.cancellationAmount {
    background-color: #ffcccc; /* Lighter red */
    color: black;
}

.fullscreen th.cancellationCount {
    background-color: orange;
    color: black;
}


.fullscreen td.cancellationCount {
    background-color: #ffe5cc; /* Lighter orange */
    color: black;
}

.fullscreen th.agent {
    background-color: aqua;
    color: black;
}


.fullscreen td.agent {
    background-color: #e0ffff; /* Lighter cyan */
    color: black;
}

.fullscreen th.dayCount {
    background-color: green;
    color: black;
}

.fullscreen td.dayCount {
    background-color: #ccffcc; /* Lighter green */
    color: black;
}

.fullscreen th.dayAmount {
    background-color: black;
    color: white;
}

.fullscreen td.dayAmount {
    background-color: #2c2c2c !important; /* Lighter black (gray) */
    color: white !important;
}

.fullscreen th.pendingCount {
    background-color: #006aff
}

.fullscreen td.pendingCount {
    background-color: #88b6f6;
    color: black;
}

.fullscreen th.pendingAmount {
    background-color: black;
    color: white;
}

.fullscreen td.pendingAmount {
    background-color: #2c2c2c !important; /* Lighter black (gray) */
    color: white !important;
}

.fullscreen th.weekCount {
background-color: purple;
color: black;
}

.fullscreen td.weekCount {
    background-color: rgb(211, 183, 211) !important; /* Lighter purple */
    color: black !important;
}

.fullscreen th.weekAmount {
    background-color: black;
    color: white;
}
.fullscreen td.weekAmount {
    background-color: #2c2c2c !important; /* Lighter black (gray) */
    color: white !important;
}

.fullscreen th.monthCount {
  background-color: rgb(207, 54, 148);
    color: black;
}
.fullscreen td.monthCount {
  background-color: rgb(238, 208, 226);  /* Lighter green */
    color: black!important;
}

.fullscreen th.monthAmount {
  background-color: black;
    color: white;
}
.fullscreen td.monthAmount {
  background-color: #2c2c2c;  /* Lighter green */
    color: white!important;
}

.fullscreen th.quarterAmount {
  background-color: rgb(180, 235, 53);
    color: black;
}
.fullscreen td.quarterAmount {
  background-color: rgb(198, 226, 171) !important; /* Lighter green */
  color: black !important;
}

.fullscreen .actions {
    background-color: black !important;
    color: white !important;
}

/* .enrollment-fullscreen tbody tr:last-child {
    display: none;
  } */

  .enrollment-fullscreen {
    position: fixed;
    top: 60px;
    left: 20px;
    right: 10px;
    bottom: 1px;
    width: 95vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: rgb(163, 163, 163);
    color: rgb(17, 17, 17);
    /* Add additional styles specific to EnrollmentPage fullscreen here */
}

/* Specific styles for the EnrollmentPage */
.enrollment-page .exit-button {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: red;
    color: whitesmoke;
    border: none;
    cursor: pointer;
    z-index: 999;
    width: 50px;
  }
  
  .enrollment-page:hover .exit-button {
    display: block;
  }
  
  .enrollment-page:hover .exit-button:hover {
    background-color: darkred;
  }

  .enrollment-fullscreen thead th {
    height: 40px;
  }

/* //////////////////create report//////////////////////////// */

  .modal-content {
    padding: 20px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .modal-form label {
    font-weight: bold;
  }
  
  .modal-form input,
  .modal-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-form input[type="checkbox"] {
    width: auto;
  }
  
  .modal-form button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-form button[type="submit"] {
    background-color: #28a745;
  }
  
  .modal-form button[type="button"] {
    background-color: #dc3545;
  }

  .ModalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px; /* Adjust the maximum width as needed */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.644);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
  }
  
  .modal-header button {
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }

  .enrollment-total-row,
  .enrollment-total-row td {
    background-color: black;
    color: white;
  }

  .fullscreen .enrollment-total-row,
.fullscreen .enrollment-total-row td {
  background-color: black;
  color: white;
}

.adminpage .filter-container {
  position: sticky;
  top: 0;
  background: #818181;
  z-index: 1000;
  padding: 2px 0;
}

.adminpage .table-container {
  margin-top: 20px;
  position: relative;
  height: 90vh;
}

.adminpage table {
  width: 100%;
  border-collapse: collapse;
}

.adminpage th, .adminpage td {
  border: 1px solid #ddd;
  padding: 8px;
}

.adminpage thead th {
  background-color: #331e36;
  position: sticky;
  top: 0;
  z-index: 1;
}

.adminpage tfoot {
  background-color: #f2f2f2;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.adminpage .footer-row {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1000;
}

.fullscreen th {
  background-color: #331e36;
  color: whitesmoke;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
}

.fullscreen .dailyFooter {
  background-color: white;
  color:black;
}

.adminpage .filter-container {
  position: sticky;
  top: 0;
  background: #a5a5a5;
  z-index: 1000;
  padding: 2px 0;
}

.adminpage .table-container {
  margin-top: 20px;
  position: relative;
}

.adminpage table {
  width: 100%;
  border-collapse: collapse;
}

.adminpage th, .adminpage td {
  border: 1px solid #ddd;
  padding: 8px;
}

.adminpage thead th {
  background-color: #242424;
  position: sticky;
  top: 0;
  z-index: 1;
}

.adminpage tfoot {
  background-color: #f2f2f2;
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.adminpage .footer-row {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 1000;
}

.fullscreen th {
  background-color: #331e36;
  color: whitesmoke;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
}

.fullscreen .dailyFooter {
  background-color: white;
  color: black;
}

.adminpage-fullscreen tr:nth-child(1) {
  background-color: black;
  color: white;
}

.adminpage-fullscreen thead th {
  background-color: #331e36;
  position: sticky;
  top: 0;
  z-index: 1;
}

.ReactModal__Overlay {
  z-index: 2000; /* Ensure the overlay is above the header */
}

.ReactModal__Content {
  z-index: 2001; /* Ensure the content is above the overlay */
}
.adminpage-fullscreen thead th:first-child,
.adminpage-fullscreen tbody th:first-child,
.adminpage-fullscreen tbody td:first-child,
.adminpage-fullscreen thead th:last-child,
.adminpage-fullscreen tbody th:last-child,
.adminpage-fullscreen tbody td:last-child {
  display: none;
}

.leaderboard-fullscreen {
  height: 97vh;
  
}

.reactModalPortal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.client-thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.table-container {
  overflow-y: auto;
}

.transfer-select {
  width: 50px;
}

/* Target the outer container of react-select */
.transfer-select__control {
  width: 100%;
  min-width: 200px; /* Match this width to the Agent dropdown */
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style the dropdown menu */
.transfer-select__menu {
  width: 100%;
  min-width: 200px; /* Ensure the menu width matches the control */
}

/* Optional: Style other parts of the select component */
.transfer-select__option {
  padding: 8px;
}

.transfer-select__multi-value {
  background-color: #e0e0e0;
}
